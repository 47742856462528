<template>
    <b-table-simple responsive fixed>
        <thead>
            <tr>
                <th v-if="editable" width="3%">
                    <Check 
                        v-model="masterCheck" 
                        @change="checked => $emit('handlerMasterSelect', checked)"
                    />
                </th>
                <th><Ellipsis>Código</Ellipsis></th>
                <th><Ellipsis>Nome</Ellipsis></th>
                <th><Ellipsis>Tabela</Ellipsis></th>
                <th><Ellipsis>Cód. Despesa</Ellipsis></th>
                <th><Ellipsis>Vínculo mat/med</Ellipsis></th>
                <th><Ellipsis>Unid. medida</Ellipsis></th>
                <th><Ellipsis>Red/Acrec</Ellipsis></th>
                <th><Ellipsis>Valor unitário</Ellipsis></th>
                <th><Ellipsis>Registro ANVISA</Ellipsis></th>
                <th><Ellipsis>Ref. fab.</Ellipsis></th>
                <th><Ellipsis>Nº Autor.</Ellipsis></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(otherExpense, index) in otherExpenses" :key="index">
                <td v-if="editable">
                    <Check 
                        :value="selecteds.includes(otherExpense.id)" 
                        @input="checked => !checked && masterCheck ? (masterCheck = false) : null"
                        @change="$emit('handlerSelect', otherExpense)" 
                    />
                </td>
                <td>{{ otherExpense.item?.code ?? '-' }}</td>
                <td><Ellipsis>{{ otherExpense.item.name }}</Ellipsis></td>
                <td><Ellipsis>{{ parseTUSSTable(otherExpense.table_number) }}</Ellipsis></td>
                <td><Ellipsis>({{ otherExpense.cd }}) - {{ parseCDType(otherExpense.cd) }}</Ellipsis></td>
                <td><Ellipsis>{{ getMatMed(otherExpense.material_medicine) }}</Ellipsis></td>
                <td><Ellipsis>{{ otherExpense.unit_measurement }}</Ellipsis></td>
                <td>{{ otherExpense.decrease_or_increase_factor }}</td>
                <td>{{ parseNumberToMoney(otherExpense.unitary_value) }}</td>
                <td><Ellipsis>{{ otherExpense.anvisa_registration  || '-' }}</Ellipsis></td>
                <td><Ellipsis>{{ otherExpense.manufacturer_reference  || '-' }}</Ellipsis></td>
                <td><Ellipsis>{{ otherExpense.authorization_number || '-' }}</Ellipsis></td>
                <td>
                    <div class="actions">
                        <Edit  
                            class="icon" 
                            v-b-tooltip.hover.rightbottom title="Editar item"
                            @click="() => $emit('edit', otherExpense)"
                        />
                        <Delete 
                            v-if="editable"
                            class="icon" 
                            v-b-tooltip.hover title="Excluir item"
                            @click="() => deleteItem(otherExpense)"
                        />
                    </div>
                </td>
            </tr>
        </tbody>
    </b-table-simple>
</template>

<script>
import { parseCDType, parseTUSSTable } from '@itemOtherExpenseProps/utils/constants'
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
    components: {
        Ellipsis: () => import('@/components/General/Ellipsis'),   
        Check: () => import('@/components/General/Check'),
        Delete: () => import('@/assets/icons/delete.svg'),
        Edit: () => import('@/assets/icons/edit.svg'),
    },
    props: {
        editable: Boolean,
        otherExpenses: {
            type: Array,
            default: []
        },
        selecteds: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            masterCheck: false
        }
    },
    methods: {
        parseCDType,
        parseTUSSTable,
        parseNumberToMoney,
        deleteItem(otherExpense) {
            this.$swal({
                icon: 'question',
                html: `<div class="body-alert">Deseja realmente excluir o <span>Item ${otherExpense.item.name}</span>?</div>`,
                showCancelButton: true,
                confirmButtonColor: '#F63220',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then(res => {
                if (res.isConfirmed) {
                    this.$emit('delete', otherExpense)
                }
            })
        },
        getMatMed(materialMedicine) {
            if (!materialMedicine?.type) return '-'
            if (materialMedicine.type === 'Simpro')  return materialMedicine.simpro?.descricao 
            if (materialMedicine.type === 'Brasindice')  return `${materialMedicine.brasindice.item} ${materialMedicine.brasindice.apres}`
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

</style>